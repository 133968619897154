import { MarketplaceItem, OfferField } from './MarketplaceItem';
import { MAX_UPLOAD_SIZE } from '../../Constants/enums';
import { getLatestContentPackageDetailsResponse, PackageDetails, PlatformDetails, PlatformProcessingStatus, PublishingTarget, SubmissionType } from './MarketplaceDetails';

export enum PackTypes {
    NONE = "Please Select a Content Type",
    AIRCRAFT = "Aircraft",
    AIRPORT = "Airport",
    MISSION = "Mission",
    LIVERY = "Livery",
    SCENERY = "Scenery",
    MISC = "Misc",
    INSTRUMENTS = "Instruments",
    BUNDLE = "Bundle",
    PACK = "Pack"
}

export class ContentPackage extends MarketplaceItem {
    packageName = new OfferField<string>("Package Name", true);
    publisherId = new OfferField<string>("Publisher Id", true);
    developerId = new OfferField<string>("Developer Id", true);
    creator = new OfferField<string>("Marketplace Creator", false);
    title = new OfferField<string>("Title", true);
    submissionId = new OfferField<string>("Submission Id", false);
    packageContentType = new OfferField<string>("Pack Type", true);
    publishingTarget = new OfferField<PublishingTarget>("Publishing Target", false, PublishingTarget.PCAndXbox);
    draft = new OfferField<PlatformDetails>("Draft", true);
    prerelease = new OfferField<PlatformDetails>("Prerelease", true);
    release = new OfferField<PlatformDetails>("Release", true);
    processingStatus = new OfferField<PlatformProcessingStatus>("ProcessingStatus", false)

    linkedSubmission: ContentPackage | null | undefined = null;

    constructor(data: any) {
        super(data);
        for (var prop in this) {
            if (data.hasOwnProperty(prop)) {
                if (this.hasOwnProperty(prop)) {
                    const offerField = this[prop] as OfferField<any>;

                    offerField.value = data[prop];
                    offerField.originalValue = data[prop];
                } else {
                    console.log(`Can't find prop "${prop}" in ${typeof this}!`, this);
                    throw new Error(`Can't find prop "${prop}" in ${typeof this}!`);
                }
            }
        }
    }

    setFieldFromName(propertyName: keyof MarketplaceItem, propertyValue?: any) {
        super.setFieldFromName(propertyName, propertyValue);

        // if (propertyName === "packType") {
        //     //console.log("removing binary due to packType change");
        //     this.binaryFile.value = null;
        //     durableOffer.validationResult.value = null;
        // }

        return this;
    }

    static getColumnHeaders(isFirstParty = false) {
        var columnStyle = {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center'
        }
        const columns = [
            {
                Header: 'Package Name',
                accessor: 'packageName', // String-based value accessors!
                maxWidth: 200,
                style: columnStyle
            },
            {
                Header: 'PC',
                columns: [
                    {
                        Header: 'Latest Version',
                        accessor: 'pcLatestVersion', // String-based value accessors!
                        style: columnStyle
                    },
                    {
                        Header: 'Status',
                        accessor: 'pcStatus', // String-based value accessors!
                        style: columnStyle
                    }
                ]
            },
            {
                Header: 'Xbox',
                columns: [
                    {
                        Header: 'Latest Version',
                        accessor: 'xboxLatestVersion', // String-based value accessors!
                        style: columnStyle
                    },
                    {
                        Header: 'Status',
                        accessor: 'xboxStatus', // String-based value accessors!
                        style: columnStyle
                    }
                ]
            }
        ]

        return columns;
    }

    convertToColumnData() {
        const reportEntry: PackageDetails<PlatformDetails, PlatformProcessingStatus> = this.export();
        const packageDetails = getLatestContentPackageDetailsResponse(reportEntry); // reportEntry.draft ?? reportEntry.prerelease ?? reportEntry.release;

        return (
            {
                packageName: reportEntry.packageName,
                pcLatestVersion: packageDetails.PC?.version ?? "",
                pcStatus: packageDetails.PC?.releaseStatus ?? "",
                xboxLatestVersion: packageDetails.Xbox?.version ?? "",
                xboxStatus: packageDetails.Xbox?.releaseStatus ?? ""
            }
        )
    }

    static get defaultListSorting() {
        return [
            {
                id: "developerId",
                desc: false
            },
            {
                id: "title",
                desc: false
            }
        ]
    }

    static get defaultFirstPartyListSorting() {
        return [
            {
                id: "creatorName",
                desc: false
            },
            {
                id: "title",
                desc: false
            }
        ]
    }

    static get MAX_FILE_SIZE() {
        return MAX_UPLOAD_SIZE;
    }
}