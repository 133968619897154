import { ROYALTY_TIERS } from './enums';
import React from 'react';
import mcoin from '../Images/mcoin.png';
import ruby from '../Images/ruby.png';
import usd from '../Images/usd.png';

export const ConvertPriceStringToLogo = (string) => {
    if(string){
        if(string.includes("Minecoins")){
            string = string.replace("Minecoins", "");
            var logoCoin = <div><img src={mcoin} alt="minecoins" /> {string}</div>;
            return logoCoin;
        }
        if(string.includes("Rubies")){
            string = string.replace("Rubies", "");
            var logoRuby = <div><img src={ruby} alt="rubies" /> {string}</div>;
            return logoRuby;
        }

        if(string.includes("USD")){
            string = string.replace("USD", "");
            var logousd = <div><img src={usd} alt="USD" /> {string} </div>;
            return logousd;
        }
        //string = string.replace("Rubies", <img src={ruby} style={{ maxWidth: 20, display: "block", margin: "auto" }} alt="rubies" />);
    }
    return string;
}

export function isURL(str) {
    var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
    '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
    return pattern.test(str);
  }

function VersionCompare(a, b) {
    if (a === b) {
        return 0;
    } else  if (!a) {
        return 1;
    } else if (!b) {
        return -1;
    }

    var a_components = a.split(".");
    var b_components = b.split(".");

    var len = Math.min(a_components.length, b_components.length);

    // loop while the components are equal
    for (var i = 0; i < len; i++) {
        // A bigger than B
        if (parseInt(a_components[i], 10) > parseInt(b_components[i], 10)) {
            return -1;
        }

        // B bigger than A
        if (parseInt(a_components[i], 10) < parseInt(b_components[i], 10)) {
            return 1;
        }
    }

    // If one's a prefix of the other, the longer one is greater.
    if (a_components.length > b_components.length) {
        return -1;
    }

    if (a_components.length < b_components.length) {
        return 1;
    }

    // Otherwise they are the same.
    return 0;
}

const GetFsOptions = (path) => {
    return {
        maxFiles: 1,
        minFiles: 1,
        maxSize: 1024 * 1024 * 1000,
        accept: ".zip",
        storeTo: {
            location: "azure",
            path: path,
        },
        fromSources: ["local_file_system", "url", "googledrive", "dropbox", "onedrive"]
    };
};

const SortOffersByTitle = (offers) => {
    offers.sort(function (a, b) {
        var nameA = a.OfferTitle.toUpperCase(); // ignore upper and lowercase
        var nameB = b.OfferTitle.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
            return -1;
        }
        if (nameA > nameB) {
            return 1;
        }
        // names must be equal
        return 0;
    });
    return offers;
};

const ConvertMapToTableRows = (mapObject) => {
    return mapObject.map(function (item) {
        if (item.value) {
            return (
                <tr key={item.id}>
                    <th htmlFor={item.id}>
                        {item.name}: &nbsp;
                    </th>
                    <td id={item.id} className="value">
                        {item.value}
                    </td>
                </tr>
            );
        }
        else {
            return (<tr key={item.id} />);
        }

    });
};

const ReduceTitle = (title) => {
    var reducedTitle = title.replace(/\w\S*/g, function (txt) { return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(); });
    reducedTitle = reducedTitle.replace(/\s/g, '');
    reducedTitle = reducedTitle.replace(/[^0-9a-z]/gi, '');
    return reducedTitle;
};

const VersionLessThanOrEqualTo = (lhs, rhs) => {
    return lhs.length !== 3 || rhs.length !== 3 || (lhs.length === 3 && lhs[0] <= rhs[0] && (lhs[0] !== rhs[0] || (lhs[1] <= rhs[1] && (lhs[1] !== rhs[1] || lhs[2] <= rhs[2]))));
}

const IsInt = (value) => {
    var x;
    if (isNaN(value)) {
        return false;
    }
    x = parseFloat(value);
    return (x | 0) === x;
};

const ResolveLatestOffer = (offer) => {
    var latestSubmission;
    latestSubmission = {
        "submissionDate": "01-01-1900"
    };
    for (var offerVersion in offer.versions) {
        var submission = offer.versions[offerVersion].info;
        var currentDate = new Date(submission.lastSubmittedDate);
        var latestDate = new Date(latestSubmission.submissionDate);
        if (currentDate > latestDate) {
            latestSubmission = submission;
            latestSubmission.version = offerVersion;
            latestSubmission.name = offer.name;
            latestSubmission.submissionDate = FormatDateTime(latestSubmission.submissionDate);
        }
        //console.log(submission.title, offerVersion);
    }
    return latestSubmission;
};

const FormatDateTime = (date, options) => {

    if (options == null) {

        options = { hour12: true, year: "numeric", month: "numeric", day: "numeric", hour: "numeric", minute: "numeric" };
    }

    var newDate = new Date(date);
    var formattedDate = newDate.toLocaleDateString(getLang(), options);
    if (!(typeof newDate) || !(newDate instanceof Date)) {
        console.log(newDate);
        return false;
    }
    return formattedDate
}

const FormatDate = (date, options) => {

    if (options == null) {

        options = { hour12: true, year: "numeric", month: "numeric", day: "numeric" };
    }
    var newDate = new Date(date);
    var formattedDate = newDate.toLocaleDateString('en-US', options);
    if (!(typeof newDate) || !(newDate instanceof Date)) {
        console.log(newDate);
        return false;
    }
    return formattedDate;
}

export const getLang = () => {
    if (navigator.languages !== undefined)
        return navigator.languages[0];
    else
        return navigator.language;
}

export const CreateDeeplink = (productId) => {
    return "minecraft://openStore/?showStoreOffer=" + productId;
}

export const makeCancelable = (promise) => {
    let hasCanceled_ = false;

    const wrappedPromise = new Promise((resolve, reject) => {
        promise.then(
            val => hasCanceled_ ? reject({ isCanceled: true }) : resolve(val),
            error => hasCanceled_ ? reject({ isCanceled: true }) : reject(error)
        );
    });

    return {
        promise: wrappedPromise,
        cancel() {
            hasCanceled_ = true;
        },
    };
};

export const GetTimelessDate = (date) => {

    if (typeof (date) === "string") {
        if (date.includes("Z")) {

        } else {
            date = date + " UTC";
        }
    }

    var options = { hour12: true, year: "numeric", month: "numeric", day: "numeric" };
    var currentDate = new Date(date).toLocaleDateString('en-US', options);
    return currentDate
}

export const GetDescriptiveRoyalty = (tier) => {

    return "Tier " + ROYALTY_TIERS[tier].royaltyTier + " - $" + parseFloat(ROYALTY_TIERS[tier].basePrice).toFixed(2) + " - Coins " + ROYALTY_TIERS[tier].price;
};

export const GetClientRoyalty = (tier) => {

    return ("Tier " + ROYALTY_TIERS[tier].royaltyTier + " - $" + parseFloat(ROYALTY_TIERS[tier].basePrice).toFixed(2));
};

export const GetWebApiUrl = () => {
    if (false || !!document.documentMode) {
        // you are using IE
        return "pleaseUseChrome";
    }
    var webApiUrl = process.env.REACT_APP_API_BASE_URL + "/api";
    console.log("WebApiUrl", webApiUrl);
    return webApiUrl;
};

export const EncodeForHtml = (string) => {
    return string.replace(new RegExp('\r?\n', 'g'), '<br />'); // eslint-disable-line no-control-regex
};

export const convertMsToDaysHoursMinutesSeconds = (ms) => {
    var days = Math.floor(ms / (24 * 60 * 60 * 1000));
    var daysms = ms % (24 * 60 * 60 * 1000);
    var hours = Math.floor((daysms) / (60 * 60 * 1000));
    var hoursms = ms % (60 * 60 * 1000);
    var minutes = Math.floor((hoursms) / (60 * 1000));
    var minutesms = ms % (60 * 1000);
    var sec = Math.floor((minutesms) / (1000));


    var msg = "";
    if (days) { msg += days + " days " }
    if (hours) { msg += hours + " hours " }
    if (minutes) { msg += minutes + " minutes " }
    msg += sec + " seconds";

    return msg;

    // return days + ":" + hours + ":" + minutes + ":" + sec + ":";
}

const FormatAsRow = (item) => {

    if (!(item.value)) {
        return;
    }

    var urlProp = "url";
    if (item.hasOwnProperty(urlProp) && item.url) {

        item.value = <a href={item.url} target="_blank" rel="noopener noreferrer"> {item.value} </a>
    }

    return (
        <tr key={item.id} className={item.id} title={item.name}>
            <th htmlFor={item.id}>
                {item.name}: &nbsp;
            </th>
            <td id={item.id} className="value">
                {item.value}
            </td>
        </tr>
    );
}

export const ValidateOptions = (options, value) => {
    var found = false;
    Object.keys(options).forEach(option => {
        if(options[option] === value){
            found = true;
        }
    });

    if(value === options.NONE){
        return 'error'
    }

    if(found){
        return 'success';
    }
    return 'error'
}

export const GetLogComment = (updateInfo, binaryFile) => {
    var updateType = " - Content Update";
    if (binaryFile === null) {
        updateType = " - Meta Only Update";
    }

    var commentType = "Change Log";
    if (updateInfo.SubmissionVersion === "1.0.0") {
        commentType = "Submission Notes";
    }

    var logComment = "<div class='changelog-" + updateInfo.SubmissionVersion + "'><strong>Version " + updateInfo.SubmissionVersion + " " + commentType + " </strong>" + updateType + "\n" + updateInfo.LogComment + "</div>";
    logComment = logComment.replace(/\n/g, "<br>");
    return logComment;
};

export const ParseAttachmentNameFromUrl = (attachment) => {

    var endIndex = attachment.indexOf("?");
    var startIndex = attachment.indexOf("_") + 1;

    if ((startIndex === -1) ||
        (endIndex === -1)) {
        return "Attachment";
    }

    // console.log(attachment, startIndex, endIndex);

    return attachment.substring(startIndex, endIndex);

}

const ResolveFormValidity = (formState) => {

    for (var field in formState) {
        if (formState[field].state !== "success" && formState[field].state !== "warning") {
            // console.log(field, formState[field]);
            return false;
        }
    }
    return true;
};

const ParseRoyaltyTierFromString = (tierString) => {

    var tierIndex = tierString.indexOf("Tier");
    var dollarIndex = tierString.indexOf("-");
    tierIndex = tierIndex + 4;

    var tierValue = "";
    for (var i = tierIndex; i < dollarIndex; i++) {
        tierValue = tierValue + tierString[i];
    }
    tierValue = tierValue.trim();
    return tierValue;
};

const ConvertVersionArrayToString = (array) => {
    var partOne = String(array[0]);
    var partTwo = String(array[1]);
    var partThree = String(array[2]);
    var solution = partOne + "." + partTwo + "." + partThree;
    return solution;
};

const GenerateAzurePath = (state) => {
    var newVersion = ResolveNextVersion(state.offerInfo.version, state.formState.updateSequence.value);
    var azurePath = "/" + state.partnerName + "/" + state.offerInfo.name + "/" + newVersion + "/content.zip";

    return azurePath;
};

const ResolveNextVersion = (oldVersion, isInitial) => {
    if (oldVersion === null || isInitial === null) {
        return "versionError";
    }
    var currentVersion = oldVersion.split(".");
    var numVersion = [Number(currentVersion[0]), Number(currentVersion[1]), Number(currentVersion[2]),];
    var newVersion;

    if (isInitial === true) {
        newVersion = numVersion;
        newVersion[1] = numVersion[1] + 1;
        newVersion[2] = 0;
        return ConvertVersionArrayToString(newVersion);
    }
    if (isInitial === false) {
        newVersion = numVersion;
        newVersion[2] = numVersion[2] + 1;
        return ConvertVersionArrayToString(newVersion);
    }

    return ConvertVersionArrayToString(numVersion);
};

export function camelPad(str) {
    return str
        // Look for long acronyms and filter out the last letter
        .replace(/([A-Z]+)([A-Z][a-z])/g, ' $1 $2')
        // Look for lower-case letters followed by upper-case letters
        .replace(/([a-z\d])([A-Z])/g, '$1 $2')
        // Look for lower-case letters followed by numbers
        .replace(/([a-zA-Z])(\d)/g, '$1 $2')
        .replace(/^./, function (str) { return str.toUpperCase(); })
        // Remove any white space left around the word
        .trim();
}

/*
const UploadMetaToAzure = (updateInfo, fsKey) => {

    const filestack = client.init(fsKey);
    var azurePath = "/" + updateInfo.partnerName + "/" + updateInfo.offerName + "/" + updateInfo.version + "/contentInfo.json";
    var storeOptions = {
        location: "azure",
        path: azurePath,
    }
    var blobFile = new Blob([JSON.stringify(updateInfo)]);
    filestack.upload(blobFile, {}, storeOptions);
}*/

const IsValidShipDate = (value, hasShipDateOverride) => {
    if (hasShipDateOverride) { return true; }
    var nowDate = new Date();
    var nextWeek = new Date(nowDate.getTime() + 7 * 24 * 60 * 60 * 1000);
    var newReleaseDate = new Date(value);
    if (!newReleaseDate.isNaN && nextWeek < newReleaseDate) {
        return true;
    }
    return false;
}

export const isShipDateWithinWeek = (value) => {
    var nowDate = new Date();
    var nextWeek = new Date(nowDate.getTime() + 7 * 24 * 60 * 60 * 1000);
    var newReleaseDate = new Date(value);
    if (!newReleaseDate.isNaN && nextWeek < newReleaseDate) {
        return true;
    }
    return false;
}

export const addDays = (date, days) => {
    var when = new Date(date);
    when.setDate(when.getDate() + days);
    return when;
}

export {
    ResolveFormValidity,
    ResolveLatestOffer,
    ResolveNextVersion,
    GenerateAzurePath,
    ParseRoyaltyTierFromString,
    //UploadMetaToAzure,
    SortOffersByTitle,
    GetFsOptions,
    ReduceTitle,
    FormatDateTime,
    VersionCompare,
    IsInt,
    ConvertMapToTableRows,
    IsValidShipDate,
    FormatAsRow,
    FormatDate,
    VersionLessThanOrEqualTo
};