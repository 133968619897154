import React from 'react';
import { MarketplaceItem, OfferField } from './MarketplaceItem';
import { AUGER_OFFER_TYPES, MAX_UPLOAD_SIZE } from '../../Constants/enums';
import { DropDownFormField, GenericCheckboxForm, NameValuePair, NameValuePairFormField, TagFormField, TextAreaFormField, TextFormField } from '../../Components/FormFields/FormField';
import { Col, Container, Row } from 'react-bootstrap';
import { MarketplaceIngestRequest, MisPackageContentType, MisTag, MisMediaAsset, MarketplaceSummary, getLatestMarketplacePackageSummaryResponse, PackageDetails, MisMediaAssetTag, MisStatus, MisRentalInfo, PlatformDetails, getLatestContentPackageDetailsResponse, getPackageNameNoPrefix, MisLocation, getPackageName2020Prefix, SubmissionType, PlatformProcessingStatus, MarketplaceSummaryData } from './MarketplaceDetails';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faPaperPlane } from '@fortawesome/free-regular-svg-icons';
import { faBan, faCheck, faGift, faQuestion, faSync, faTrash, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import placeholder from "../../Images/Placeholder.jpg";
import { MarketplaceProductBrowserData } from '../../Components/MarketplaceProductBrowser';

interface CategorySections {
    [key: string]: string[];
}

interface Props {
    marketplacePackageSummaries: MarketplaceSummaryData[];
    contentPackagesDetails: PackageDetails<PlatformDetails, PlatformProcessingStatus>[];
}

export class MarketplacePackage extends MarketplaceItem {
    marketplacePackageSummaries: MarketplaceSummary[];
    contentPackagesDetails: PlatformDetails[];

    submissionId = new OfferField<string>("Submission Id", false);
    packageContentType = new OfferField<string>("Pack Type", true);
    packageVersion = new OfferField<string>("Package Version", false);
    publisherId = new OfferField<string>("Publisher ID", true);
    developerId = new OfferField<string>("Developer Id", true);
    priceInUSD = new OfferField<string>("Price In USD", true);
    offerType = new OfferField<string>("Offer Type", true, AUGER_OFFER_TYPES.MIS_OFFER);
    packageName = new OfferField<string>("Package Name", true);
    submissionType = new OfferField<string>("Submission Type", false);
    title = new OfferField<string>("Title", true);
    category = new OfferField<string>("Category", true);
    section = new OfferField<string>("Section", true);
    ingestionStatus = new OfferField<string>("Ingestion Status", false);
    ingestionVersion = new OfferField<number>("Ingestion Version", false);
    mediaAssets = new OfferField<MisMediaAsset[]>("Media Assets", false, []);
    deletedFiles = new OfferField<string[]>("Deleted Files", false, []);
    description = new OfferField<string>("Product Description", true);
    historicalDescription = new OfferField<string>("Historical Description", true);
    keywords = new OfferField<string[]>("Keywords", false, []);
    tags = new OfferField<string[]>("Tags", false, [MisTag.STORE_OFFICIAL]);
    creator = new OfferField<string>("Marketplace Creator", false);
    enableUpgradePrice = new OfferField<boolean>("Enable Upgrade Price", false);
    upgradePriceInUSD = new OfferField<string>("Upgrade Price In USD", false);
    rentalInfo = new OfferField<MisRentalInfo>("Rental Info", false);
    aircraftSpecifications = new OfferField<NameValuePair[]>("Aircraft Specifications", false, []);
    supportContact = new OfferField<string>("Support Contact", false);
    secondSupportContactLabel = new OfferField<string>("Second Support Contact Label", false);
    secondSupportContactLink = new OfferField<string>("Second Support Contact Link", false);
    locations = new OfferField<MisLocation[]>("Locations", false);

    constructor(data: any) {
        super(data);

        this.marketplacePackageSummaries = data.marketplacePackageSummaries.map((marketplacePackageDetails: MarketplaceSummaryData) => getLatestMarketplacePackageSummaryResponse(marketplacePackageDetails));
        this.contentPackagesDetails = data.contentPackagesDetails.map((contentPackageDetails: PackageDetails<PlatformDetails, PlatformProcessingStatus>) => getLatestContentPackageDetailsResponse(contentPackageDetails));
        
        for (var prop in this) {
            if (data.hasOwnProperty(prop)) {
                if (this.hasOwnProperty(prop)) {
                    const offerField = this[prop] as OfferField<any>;

                    offerField.value = data[prop];
                    offerField.originalValue = data[prop];
                } else {
                    console.log(`Can't find prop "${prop}" in ${this.offerType.value} model!`, this);
                    throw new Error(`Can't find prop "${prop}" in ${this.offerType.value} model!`);
                }
            }
        }

        if (this.priceInUSD.value) {
            let price = parseFloat(this.priceInUSD.value as string);

            this.priceInUSD.value = price.toFixed(2);
        }

        if ((this.mediaAssets.value?.length ?? 0) === 0) {
            fetch(placeholder).then(response => response.blob()).then(blob => {
                if (this.mediaAssets.value) {
                    this.mediaAssets.value.push({ filename: "Placeholder.jpg", file: blob, url: URL.createObjectURL(blob), tags: [MisMediaAssetTag.Thumbnail] });
                } else {
                    this.mediaAssets.value = [{ filename: "Placeholder.jpg", file: blob, url: URL.createObjectURL(blob), tags: [MisMediaAssetTag.Thumbnail] }];
                }
            });
        }

        this.title.validate = function (offer?: MarketplacePackage, value?: string, originalValue?: string) {
            let matchingSubmission = value && offer?.marketplacePackageSummaries?.find(s => s?.title === value && s.publisherId === offer.publisherId.value && getPackageNameNoPrefix(s.packageName) !== getPackageNameNoPrefix(offer.packageName.value));
            let needsICAO = false;

            if (offer?.packageContentType.value !== MisPackageContentType.Bundle && offer?.packageContentType.value !== MisPackageContentType.Pack && (offer?.category.value as string)?.toLowerCase().includes('airport')) {
                needsICAO = !value || /^[A-Z0-9]{3,5} [^- ].+/.test(value) === false;
            }

            const icaoWarning = "Title must start with 3-5 character ICAO and no dash.";
            const titleMismatch = false; // offer.manifestTitle.value !== null && value !== offer.manifestTitle.value;

            return {
                result: !value || needsICAO || value.length > 50 || value.length <= 2 || value.trim().length !== value.length || matchingSubmission ? 'error' : titleMismatch || (originalValue && value !== originalValue) ? 'warning' : 'success',
                message: !value || value.length <=2 ? "Title too short." : needsICAO ? icaoWarning : value.length > 50 ? "Title too long." : value.trim().length !== value.length ? "Enter valid title." : matchingSubmission ? "Work item already exists with the same title." : titleMismatch ? "Marketplace title does not match the the manifest." : originalValue && value !== originalValue ? "Title changed." : "",
                requestConfirmation: true
            }
        } 

        this.packageName.validate = function (marketplacePackage?: MarketplacePackage, value?: string, originalValue?) {
            if (!value || value === "Upload new Content Package...") {
                return {
                    result: null
                }
            }

            const fs2020PackageName = getPackageName2020Prefix(value);
            const marketplacePackageSummary = marketplacePackage?.marketplacePackageSummaries?.find(marketplacePackageDetails => marketplacePackageDetails?.packageName === fs2020PackageName);
            const contentPackageDetails = marketplacePackage?.contentPackagesDetails?.find(contentPackageDetails => contentPackageDetails?.PC?.packageName === fs2020PackageName);
            const needsUpgradedPackage = marketplacePackageSummary != null && contentPackageDetails != null;
            let packageNameStartsWithShortName = getPackageNameNoPrefix(value)?.startsWith(marketplacePackage?.publisherId.value as string);
            let matchingSubmission = marketplacePackage?.submissionId.value === undefined && marketplacePackage?.marketplacePackageSummaries?.find(marketplacePackageDetails => getPackageNameNoPrefix(marketplacePackageDetails?.packageName) === value) !== undefined;
            let packageNameNotFoundError = 'Package name not found. Please verify you are using the latest SDK, do a "Clean All", then "Build & Export" and choose "Flight Simulator Marketplace."'

            return {
                result: !needsUpgradedPackage && (!originalValue || originalValue === '' || value === originalValue) && value.length <= 100 && value.length > 2 && packageNameStartsWithShortName && (matchingSubmission === false) ? 'success' : 'error',
                message : needsUpgradedPackage ? "A 2024 package must be uploaded first." : value.length > 100 ? "Package name too long." : value.length === 0 ? packageNameNotFoundError : value.length <=2 ? "Package name too short." : value.trim().length !== value.length ? "Enter valid package name." : (packageNameStartsWithShortName === false) ? "Package name must start with short name" :  matchingSubmission ? "Marketplace product already exists with the same package name." : "Package name for a resubmission or update must be the same as the previous submission. Please correct the package name and resubmit."
            };
        } 

        this.priceInUSD.validate = function (marketplacePackage?: MarketplacePackage, value?: string, originalValue?: string) {
            if (!value) {
                return {
                    result: null
                }
            }
            
            const valueAsNumber = !value ? 0 : parseFloat(value);

            return {
                result: !value || valueAsNumber < 0 || valueAsNumber >= 200 ? 'error' : (originalValue && value !== originalValue) || (valueAsNumber >= 0 && valueAsNumber < 4.99) ? 'warning' : 'success',
                message: !value || valueAsNumber < 0 || valueAsNumber >= 200 ? "Price should be between $4.99 and $199.99." : originalValue && value !== originalValue ? 'Price changed.' : valueAsNumber >= 0 && valueAsNumber < 4.99 ? "Price less than $4.99 may result in ingestion failure." : "",
                requestConfirmation: true
            };
        }

        this.description.validate = function (marketplacePackage?: MarketplacePackage, value?: string, originalValue?: string) {
            if (!value) {
                return {
                    result: null
                }
            }
            
            return {
                result: originalValue && value !== originalValue ? 'warning' : (value?.length ?? 0) > 10000 || (value?.length ?? 0) === 0 ? 'error' : 'success',
                message: originalValue && value !== originalValue ? 'Description changed.' : (value?.length ?? 0) > 10000 ? "10000 character limit exceeded." : (value?.length ?? 0) === 0 ? "Enter a description." : ''
            };
        }

        this.historicalDescription.validate = function (marketplacePackage?: MarketplacePackage, value?: string, originalValue?: string) {
            return {
                result: originalValue && value !== originalValue ? 'warning' : (value?.length ?? 0) > 10000 ? 'error' : 'success',
                message: originalValue && value !== originalValue ? 'Historical Description changed.' : (value?.length ?? 0) > 10000 ? "10000 character limit exceeded." : ''
            };
        }

        this.category.validate = function (marketplacePackage?: MarketplacePackage, value?: string, originalValue?: string) {
            if (!value) {
                return {
                    result: null
                }
            }
            
            return {
                result: originalValue && value !== originalValue ? 'warning' : 'success',
                message: "Category changed."
            };
        }

        this.section.validate = function (marketplacePackage?: MarketplacePackage, value?: string, originalValue?: string) {
            if (!value) {
                return {
                    result: null
                }
            }
            
            return {
                result: originalValue && value !== originalValue ? 'warning' : 'success',
                message: "Section changed."
            };
        }

        this.creator.validate = function (marketplacePackage?: MarketplacePackage, value?: string, originalValue?: string) {
            if (!value) {
                return {
                    result: null
                }
            }
            
            return {
                result: originalValue && value !== originalValue ? 'warning' : (value?.length ?? 0) > 100 || (value?.length ?? 0) === 0 ? 'error' : 'success',
                message: originalValue && value !== originalValue ? 'Creator changed.' : (value?.length ?? 0) > 100 ? "100 character limit exceeded." : (value?.length ?? 0) === 0 ? "Enter a Creator." : ''
            };
        }

        this.mediaAssets.validate = function (marketplacePackage?: MarketplacePackage, value?: MisMediaAsset[], originalValue?: MisMediaAsset[]) {
            if (!value) {
                return {
                    result: null
                }
            }
            
            const containsThumbnail = value?.find(mediaAsset => mediaAsset.tags.find(tag => tag === MisMediaAssetTag.Thumbnail) !== undefined) !== undefined;
            const allHaveTags = value?.every(mediaAsset => mediaAsset.tags.length > 0);

            return {
                result: (value?.length ?? 0) > 0 && containsThumbnail && allHaveTags ? "success" : "error",
                message: !containsThumbnail ? "There must be at least one media asset with the Thumbnail tag." : !allHaveTags ? "Every media asset must have a tag." : ""
            }
        }
    }

    get MarketplaceIngestRequest(): MarketplaceIngestRequest {
        return {
            submissionId: this.submissionId.value as string,
            businessInfo: {
                priceInUSD: this.priceInUSD.value as string,
                upgradePriceInUSD: this.upgradePriceInUSD.value,
                thirdPartyShortName: this.publisherId.value as string
            },
            packageName: this.packageName.value as string,
            developerId: this.developerId.value as string,
            publisherId: this.publisherId.value as string,
            marketplaceInfo: {
                title: this.title.value as string,
                creator: this.creator.value as string,
                keywords: this.keywords.value,
                tags: this.tags.value as MisTag[],
                layout: { mediaAssets: this.mediaAssets.value ?? []},
                packageContentType: this.packageContentType.value as MisPackageContentType,
                sections: [{
                    category: (this.category.value as string).toUpperCase(),
                    section: (this.section.value as string).toUpperCase()
                }],
                locations: this.locations.value,
                aircraftSpecifications: this.aircraftSpecifications.value,
                supportContact: this.supportContact.value,
                secondSupportContactLabel: this.secondSupportContactLabel.value,
                secondSupportContactLink: this.secondSupportContactLink.value
            },
            rentalInfo: this.rentalInfo.value,
            localizedTexts: {
                "en-US": {
                    "texts": {
                        "Description": this.description.value,
                        "historicalDescription": this.historicalDescription.value ?? ""
                    }
                }
            },
            deleteExistingFiles: false,
            deletedFilenames: this.deletedFiles.value,
            waitForContentIngestion: true
        }
    }

    getSubmissionFormFields(formInfoCallback: <V>(offer: MarketplacePackage | undefined, propertyName: keyof MarketplacePackage, value?: V) => void, isNew: boolean, validate: boolean, enablePCAndXbox: boolean, enablePCOnly: boolean, enableXboxOnly: boolean, context?: any): JSX.Element {
        const offerTypeOptions = Object.entries(MisPackageContentType).map(([key, value]) => {
            return {
                option: value,
                disabled: false
            };
        });

        const categorySections: CategorySections = {
            "AIRCRAFT": [
                "AIRPLANE",
                "AVIONIC",
                "GLIDER",
                "HELICOPTER",
                "LIGHTER THAN AIR",
                "OTHER"
            ],
            "AIRPORTS": [
                "AIRPORT",
                "HELIPORT",
                "SEAPLANE BASE",
                "OTHER"
            ],
            "WORLD": [
                "LANDMARK",
                "SCENERY",
                "ENVIRONMENT",
                "WORLD UPDATE",
                "TRAFFIC",
                "WILDLIFE",
                "GROUND SERVICE",
                "LIGHTING",
                "OTHER"
            ],
            "CUSTOMIZATION": [
                "LIVERY",
                "SOUND PACKAGE",
                "CHARACTER",
                "EFB APPLICATION",
                "TOOL",
                "OTHER"
            ]
        }

        const categoryOptions = Object.keys(categorySections).map(value => { return { option: value, disabled: false } });
        const sectionOptions = categorySections[(this.category.value as string)?.toUpperCase() ?? categoryOptions[0].option].map(value => { return { option: value, disabled: false } });
        const developerIdOptions = (context?.developerIds as string[])?.map((developerId: string) => { return { option: developerId, disabled: false }});
        const packageNameOptions = (context?.packageNames as string[])?.map((packageName: string) => { return { option: packageName, disabled: false }});

        if (this.packageName.value && !packageNameOptions.find(o => o.option === this.packageName.value)) {
            packageNameOptions?.push({ option: getPackageNameNoPrefix(this.packageName.value) as string, disabled: false });
        }

        packageNameOptions?.push({ option: "Upload new Content Package...", disabled: false });

        const tagOptions = Object.keys(MisTag).map(value => { return { key: value, value: value } });
        const enableUpgradePrice = this.enableUpgradePrice.value || this.packageName.value?.startsWith("fs20-");

        return (
            <Container key={"details"}>
                <Row className="show-grid">
                    <Col md={4}>
                        <TextFormField<MarketplacePackage>
                            key={"title"}
                            context={this}
                            value={this.title.value}
                            title={"Title"}
                            placeholder={"Title"}
                            helpContent={"Extracted from package."}
                            isNew={isNew}
                            validate={validate}
                            propertyName={"title"}
                            returnCallback={formInfoCallback}
                            offerUndo={false}
                            validateCallback={this.title.validate} />
                    </Col>
                    <Col md={(enableUpgradePrice || this.upgradePriceInUSD.value != null) ? 2 : 4}>
                        <TextFormField<MarketplacePackage>
                            key={"priceInUSD"}
                            context={this}
                            value={this.priceInUSD.value}
                            title={"Price"}
                            placeholder={"9.99"}
                            helpContent={"Extracted from package."}
                            isNew={isNew}
                            validate={validate}
                            propertyName={"priceInUSD"}
                            returnCallback={formInfoCallback}
                            offerUndo={false}
                            validateCallback={this.priceInUSD.validate} />
                    </Col>
                    {(enableUpgradePrice || this.upgradePriceInUSD.value != null) && <Col md={2}>
                        <TextFormField<MarketplacePackage>
                            key={"upgradePriceInUSD"}
                            context={this}
                            value={this.upgradePriceInUSD.value}
                            title={"Upgrade Price"}
                            placeholder={"9.99"}
                            helpContent={"Extracted from package."}
                            isNew={isNew}
                            validate={validate}
                            propertyName={"upgradePriceInUSD"}
                            returnCallback={formInfoCallback}
                            offerUndo={false}
                            validateCallback={this.upgradePriceInUSD.validate} />
                    </Col>}
                    <Col md={4}>
                        <DropDownFormField<MarketplacePackage>
                            key={"packageName"}
                            context={this}
                            value={getPackageNameNoPrefix(this.packageName.value)}
                            title={"Package Name"}
                            placeholder={"Select Package Name"}
                            helpContent={"Extracted from package."}
                            isNew={isNew}
                            validate={validate}
                            disabled={!isNew}
                            propertyName={"packageName"}
                            returnCallback={formInfoCallback}
                            offerUndo={false}
                            validateCallback={this.packageName.validate} 
                            optionsList={packageNameOptions} />
                    </Col>
                </Row>
                <Row className="show-grid">
                    <Col md={4}>
                        <DropDownFormField<MarketplacePackage>
                            key={"packageContentType"}
                            context={this}
                            value={this.packageContentType.value ?? MisPackageContentType.Unknown}
                            title={"Content Type"}
                            helpContent={"Extracted from package."}
                            isNew={isNew}
                            validate={validate}
                            disabled={true}
                            propertyName={"packageContentType"}
                            returnCallback={formInfoCallback}
                            offerUndo={false}
                            validateCallback={this.packageContentType.validate}
                            optionsList={offerTypeOptions} />
                    </Col>
                    <Col md={4}>
                        <DropDownFormField<MarketplacePackage>
                            key={"category"}
                            context={this}
                            value={this.category.value?.toUpperCase()}
                            title={"Category"}
                            placeholder={"Select Category"}
                            helpContent={"Extracted from package."}
                            isNew={isNew}
                            validate={validate}
                            propertyName={"category"}
                            returnCallback={formInfoCallback}
                            offerUndo={false}
                            validateCallback={this.category.validate}
                            optionsList={categoryOptions} />
                    </Col>
                    <Col md={4}>
                        <DropDownFormField<MarketplacePackage>
                            key={"section"}
                            context={this}
                            value={this.section.value?.toUpperCase()}
                            title={"Section"}
                            placeholder={"Select Section"}
                            helpContent={"Extracted from package."}
                            isNew={isNew}
                            validate={validate}
                            propertyName={"section"}
                            returnCallback={formInfoCallback}
                            offerUndo={false}
                            validateCallback={this.section.validate}
                            optionsList={sectionOptions} />
                    </Col>
                </Row>
                <Row className="show-grid">
                    <Col md={3}>
                        <TextFormField<MarketplacePackage>
                            key={"publisherId"}
                            context={this}
                            value={this.publisherId.value}
                            title={"Publisher Id"}
                            placeholder={"Select Publisher Id"}
                            helpContent={"Extracted from package."}
                            isNew={isNew}
                            validate={validate}
                            disabled={true}
                            propertyName={"publisherId"}
                            returnCallback={formInfoCallback}
                            offerUndo={false}
                            validateCallback={this.publisherId.validate} />
                    </Col>
                    <Col md={3}>
                        <DropDownFormField<MarketplacePackage>
                            key={"developerId"}
                            context={this}
                            value={this.developerId.value}
                            title={"Developer Id"}
                            placeholder={"Select Developer Id"}
                            helpContent={"Extracted from package."}
                            isNew={isNew}
                            validate={validate}
                            disabled={!isNew}
                            sort={true}
                            propertyName={"developerId"}
                            returnCallback={formInfoCallback}
                            offerUndo={false}
                            validateCallback={this.developerId.validate}
                            optionsList={developerIdOptions ?? []} />
                    </Col>
                    <Col md={6}>
                        <TextFormField<MarketplacePackage>
                            key={"marketplaceCreator"}
                            context={this}
                            value={this.creator.value}
                            title={"Creator"}
                            placeholder={"Enter Creator"}
                            isNew={isNew}
                            validate={validate}
                            propertyName={"creator"}
                            returnCallback={formInfoCallback}
                            offerUndo={false}
                            validateCallback={this.creator.validate} />
                    </Col>
                </Row>
                <Row className="show-grid">
                    <Col md={4}>
                        <TextFormField<MarketplacePackage>
                                key={"supportContact"}
                                context={this}
                                value={this.supportContact.value}
                                offerField={this.supportContact}
                                title={"Support Contact"}
                                placeholder={"Select Support Contact"}
                                helpContent={"Extracted from package."}
                                isNew={isNew}
                                validate={validate}
                                propertyName={"supportContact"}
                                returnCallback={formInfoCallback}
                                offerUndo={false}
                                validateCallback={this.supportContact.validate} />
                    </Col>
                    <Col md={4}>
                        <TextFormField<MarketplacePackage>
                                key={"secondSupportContactLabel"}
                                context={this}
                                value={this.secondSupportContactLabel.value}
                                offerField={this.secondSupportContactLabel}
                                title={"Second Support Contact Label"}
                                placeholder={"Select Second Support Contact Label"}
                                helpContent={"Extracted from package."}
                                isNew={isNew}
                                validate={validate}
                                propertyName={"secondSupportContactLabel"}
                                returnCallback={formInfoCallback}
                                offerUndo={false}
                                validateCallback={this.secondSupportContactLabel.validate} />
                    </Col>
                    <Col md={4}>
                        <TextFormField<MarketplacePackage>
                                key={"secondSupportContactLink"}
                                context={this}
                                value={this.secondSupportContactLink.value}
                                offerField={this.secondSupportContactLink}
                                title={"Second Support Contact Link"}
                                placeholder={"Select Second Support Contact Link"}
                                helpContent={"Extracted from package."}
                                isNew={isNew}
                                validate={validate}
                                propertyName={"secondSupportContactLink"}
                                returnCallback={formInfoCallback}
                                offerUndo={false}
                                validateCallback={this.secondSupportContactLink.validate} />
                    </Col>
                </Row>
                <Row className="show-grid">
                    <Col md={this.packageContentType.value === MisPackageContentType.Aircraft ? 8 : 12}>
                        <Row>
                            <TextAreaFormField<MarketplacePackage>
                                key={"productDescription"}
                                context={this}
                                value={this.description.value}
                                title={"Product Description"}
                                placeholder={"Enter Product Description"}
                                helpContent={"10000 character limit"}
                                propertyName={"description"}
                                isNew={isNew}
                                showPreviousValue={false}
                                validate={validate}
                                returnCallback={formInfoCallback}
                                validateCallback={this.description.validate} />
                        </Row>
                        {context.auth.isInternal() && <Row>
                            <TextAreaFormField<MarketplacePackage>
                                key={"historicalDescription"}
                                context={this}
                                value={this.historicalDescription.value}
                                title={"Historical Description"}
                                placeholder={"Enter Historical Description"}
                                helpContent={"10000 character limit"}
                                propertyName={"historicalDescription"}
                                isNew={isNew}
                                showPreviousValue={false}
                                validate={false}
                                returnCallback={formInfoCallback}
                                validateCallback={this.historicalDescription.validate} />
                        </Row>}
                    </Col>
                    {this.packageContentType.value === MisPackageContentType.Aircraft && <Col md={4}>
                        <NameValuePairFormField<MarketplacePackage>
                            key={"aircraftSpecifications"}
                            context={this}
                            value={this.aircraftSpecifications.value ?? []}
                            title={"Aircraft Specifications"}
                            propertyName='aircraftSpecifications'
                            isNew={isNew}
                            validate={validate}
                            returnCallback={formInfoCallback}
                            validateCallback={this.aircraftSpecifications.validate} />
                    </Col>}
                </Row>
                <Row className="show-grid">
                    <Col>
                        <TagFormField<MarketplacePackage>
                            key={"keywords"}
                            context={this}
                            value={this.keywords.value}
                            title={"Keywords"}
                            placeholder={"Enter keywords"}
                            helpContent={"Extracted from package."}
                            isNew={isNew}
                            validate={validate}
                            propertyName={"keywords"}
                            returnCallback={formInfoCallback}
                            offerUndo={false} />
                    </Col>
                </Row>
                {context.auth.isInternal() && <Row className="show-grid">
                    <Col>
                        <TagFormField<MarketplacePackage>
                            key={"tags"}
                            context={this}
                            value={this.tags.value as string[]}
                            offerField={this.tags}
                            title={"Tags"}
                            placeholder={"TAG"}
                            helpContent={"Extracted from package."}
                            isNew={isNew}
                            propertyName={"tags"}
                            returnCallback={formInfoCallback}
                            offerUndo={false}
                            optionsList={tagOptions} />
                    </Col>
                </Row>}
                {context.auth.isInternal() && <Row className="show-grid">
                    <Col>
                        <GenericCheckboxForm<MarketplacePackage>
                            key={"enableUpgradePrice"}
                            context={this}
                            value={this.enableUpgradePrice.value || this.packageName.value?.startsWith("fs20-") || this.upgradePriceInUSD.value != null}
                            helpContent={"Extracted from package."}
                            isNew={isNew}
                            disabled={this.packageName.value?.startsWith("fs20-") || this.upgradePriceInUSD.value != null}
                            validate={validate}
                            propertyName={"enableUpgradePrice"}
                            returnCallback={formInfoCallback}
                            checkboxFields={[{value: true, name: 'Enable Upgrade Price'}]} 
                            offerUndo={false} />
                    </Col>
                </Row>}
            </Container>);
    }

    setFieldFromName(propertyName: keyof MarketplaceItem, propertyValue?: any) {
        super.setFieldFromName(propertyName, propertyValue);

        // if (propertyName === "packType") {
        //     //console.log("removing binary due to packType change");
        //     this.binaryFile.value = null;
        //     durableOffer.validationResult.value = null;
        // }

        return this;
    }

    static getMarketplaceStatus(processingStatus: SubmissionType, details?: MarketplaceSummary) {
        return details?.ingestionStatus ?
            details?.ingestionStatus === MisStatus.Error ? MisStatus.Error :
            details?.ingestionStatus !== MisStatus.Live || processingStatus && processingStatus !== SubmissionType.None ?
                MisStatus.Processing :
                MisStatus.Live
            : "";
    }

    static getPCStatus(platformProcessingStatus: PlatformProcessingStatus, details?: PlatformDetails) {
        return details?.PC?.version ?
            (details?.PC?.isIngestionError ?
                "Error" :
                platformProcessingStatus?.PC && platformProcessingStatus?.PC !== SubmissionType.None || platformProcessingStatus?.PCAndXbox && platformProcessingStatus?.PCAndXbox !== SubmissionType.None ?
                    "Pending" :
                    details?.PC?.isIngested || details?.PC?.isReleased ?
                        "Live" :
                        "Unknown") :
            "";
    }

    static getXboxStatus(platformProcessingStatus: PlatformProcessingStatus, details?: PlatformDetails) {
        return details?.Xbox?.version ?
            (details?.Xbox?.isIngestionError ?
                "Error" :
                platformProcessingStatus?.Xbox && platformProcessingStatus?.Xbox !== SubmissionType.None || platformProcessingStatus?.PCAndXbox && platformProcessingStatus?.PCAndXbox !== SubmissionType.None ?
                    "Pending" :
                    details?.Xbox?.isIngested || details?.PC?.isReleased ?
                        "Live" :
                        "Unknown") :
            "";
    }

    static  parseSubmissionId(submissionId?: string) {
        const timestamp = submissionId?.split("Z")[0];

        if (timestamp?.length === submissionId?.length) {
            return submissionId;
        } else {
            return timestamp ? new Date(timestamp.replaceAll("_", ":") + "Z").toLocaleString() : "";
        }
    }

    static getColumnHeaders(onEdit: (submission: MarketplaceSummaryData) => void, onRelease: (index: number, submission: MarketplaceSummaryData) => void, onDelete: (index: number, submission: MarketplaceSummaryData) => void, onEditContentPackage: (index: number, data: MarketplaceSummaryData) => void, isInternal = false, isAdministrator = false, displayIngestedDate = false) {
        const columns = [
            {
                id: "publisherId",
                Header: 'Publisher Id',
                accessor: (row: MarketplaceProductBrowserData) => row.marketplacePackageSummary.publisherId,
                maxWidth: 20
            },
            {
                id: "developerId",
                Header: 'Developer Id',
                accessor: (row: MarketplaceProductBrowserData) => row.marketplacePackageSummary.developerId,
                maxWidth: 20
            },
            {
                id: 'title',
                Header: 'Title',
                accessor: (row: MarketplaceProductBrowserData) => getLatestMarketplacePackageSummaryResponse(row.marketplacePackageSummary)?.title ?? "Error"
            },
            {
                Header: 'Marketplace',
                columns: displayIngestedDate ? [
                    {
                        id: 'marketplaceReleaseStatus',
                        Header: 'Stage',
                        accessor: (row: MarketplaceProductBrowserData) => {
                            return getLatestMarketplacePackageSummaryResponse(row.marketplacePackageSummary)?.releaseStatus ?? "";
                        },
                        maxWidth: 15
                    },
                    {
                        id: 'marketplaceIngestedDate',
                        Header: 'Ingested',
                        accessor: (row: MarketplaceProductBrowserData) => {
                            return this.parseSubmissionId(getLatestMarketplacePackageSummaryResponse(row.marketplacePackageSummary)?.submissionId ?? "");
                        },
                        maxWidth: 30
                    },
                ] : [
                    {
                        id: 'marketplaceIngestionStatus',
                        Header: 'Status',
                        accessor: (row: MarketplaceProductBrowserData) => this.getMarketplaceStatus(row.marketplacePackageSummary.processingStatus, getLatestMarketplacePackageSummaryResponse(row.marketplacePackageSummary)),
                        maxWidth: 10,
                        Cell: ({row}: {row: {index: number, original: MarketplaceProductBrowserData, values: any}}) => {
                            if (row.values["marketplaceIngestionStatus"] === MisStatus.Commited || row.values["marketplaceIngestionStatus"] === MisStatus.Processing) {
                                return <FontAwesomeIcon className='fa-spin' icon={faSync}/>;
                            } else if (row.values["marketplaceIngestionStatus"] === MisStatus.Error) {
                                return <FontAwesomeIcon icon={faTriangleExclamation} style={{color: "red"}}/>;
                            } else if (row.values["marketplaceIngestionStatus"] === MisStatus.Live) {
                                return <FontAwesomeIcon icon={faCheck} style={{color: "green"}}/>;
                            }

                            return "";
                        }
                    },
                    {
                        id: 'marketplaceReleaseStatus',
                        Header: 'Stage',
                        accessor: (row: MarketplaceProductBrowserData) => {
                            return getLatestMarketplacePackageSummaryResponse(row.marketplacePackageSummary)?.releaseStatus ?? "";
                        },
                        maxWidth: 15
                    },
                    {
                        id: "marketplacGametype",
                        Header: 'Type',
                        accessor: (row: MarketplaceProductBrowserData) => getLatestMarketplacePackageSummaryResponse(row.marketplacePackageSummary)?.packageName?.startsWith("fs20") ? "2020" : "2024",
                        maxWidth: 10
                    }
                ]
            },
            {
                Header: 'Content',
                columns: displayIngestedDate ? [
                    {
                        id: 'contentReleaseStatus',
                        Header: 'Stage',
                        accessor: (row: MarketplaceProductBrowserData) => {
                            const pcReleaseStatus = row.contentPackageDetails && getLatestContentPackageDetailsResponse(row.contentPackageDetails)?.PC?.releaseStatus;
                            const xboxReleaseStatus = row.contentPackageDetails && getLatestContentPackageDetailsResponse(row.contentPackageDetails)?.Xbox?.releaseStatus;

                            if (!pcReleaseStatus && !xboxReleaseStatus) {
                                return "";
                            } else if (pcReleaseStatus === "Draft" || xboxReleaseStatus === "Draft") {
                                return "Draft";
                            } else if (pcReleaseStatus === "Prerelease" || xboxReleaseStatus === "Prerelease") {
                                return "Prerelease";
                            } else if (pcReleaseStatus === "Release" || xboxReleaseStatus === "Release") {
                                return "Release";
                            } else {
                                return "Error";
                            }
                        },
                        maxWidth: 15,
                    },
                    {
                        id: 'contentIngestedDate',
                        Header: 'Ingested',
                        accessor: (row: MarketplaceProductBrowserData) => {
                            const pcIngestedDate = row.contentPackageDetails && this.parseSubmissionId(getLatestContentPackageDetailsResponse(row.contentPackageDetails)?.PC?.submissionId ?? "");
                            const xboxIngestedDate = row.contentPackageDetails && this.parseSubmissionId(getLatestContentPackageDetailsResponse(row.contentPackageDetails)?.Xbox?.submissionId ?? "");

                            return (pcIngestedDate ?? 0) > (xboxIngestedDate ?? 0) ? pcIngestedDate : xboxIngestedDate;
                        },
                        maxWidth: 30
                    },
                ] : [
                    {
                        id: 'contentIngestionStatus',
                        Header: 'Status',
                        accessor: (row: MarketplaceProductBrowserData) => {
                            if (row.contentPackageDetails) {
                                const pcStatus = this.getPCStatus(row.contentPackageDetails.processingStatus, getLatestContentPackageDetailsResponse(row.contentPackageDetails));
                                const xboxStatus = this.getXboxStatus(row.contentPackageDetails.processingStatus, getLatestContentPackageDetailsResponse(row.contentPackageDetails));

                                if (pcStatus === "Error" || xboxStatus === "Error") {
                                    return "Error";
                                } else if (pcStatus === "Pending" || xboxStatus === "Pending") {
                                    return "Pending";
                                } else if (pcStatus === "Unknown" || xboxStatus === "Unknown") {
                                    return "Unknown";
                                } else {
                                    return "Live";
                                }
                            }
                         },
                        maxWidth: 10,
                        Cell: ({row}: {row: {index: number, original: MarketplaceProductBrowserData, values: any}}) => {
                            if (row.values["contentIngestionStatus"] === "Pending") {
                                return <FontAwesomeIcon className='fa-spin' icon={faSync}/>;
                            } else if (row.values["contentIngestionStatus"] === "Error") {
                                return <FontAwesomeIcon icon={faTriangleExclamation} style={{color: "red"}}/>;
                            } else if (row.values["contentIngestionStatus"] === "Live") {
                                return <FontAwesomeIcon icon={faCheck} style={{color: "green"}}/>;
                            } else if (row.values["contentIngestionStatus"] === "Unknown") {
                                return <FontAwesomeIcon icon={faQuestion} style={{color: "orange"}}/>;
                            }

                            return "";
                        }
                    },
                    {
                        id: 'contentReleaseStatus',
                        Header: 'Stage',
                        accessor: (row: MarketplaceProductBrowserData) => {
                            const pcReleaseStatus = row.contentPackageDetails && getLatestContentPackageDetailsResponse(row.contentPackageDetails)?.PC?.releaseStatus;
                            const xboxReleaseStatus = row.contentPackageDetails && getLatestContentPackageDetailsResponse(row.contentPackageDetails)?.Xbox?.releaseStatus;

                            if (!pcReleaseStatus && !xboxReleaseStatus) {
                                return "";
                            } else if (pcReleaseStatus === "Draft" || xboxReleaseStatus === "Draft") {
                                return "Draft";
                            } else if (pcReleaseStatus === "Prerelease" || xboxReleaseStatus === "Prerelease") {
                                return "Prerelease";
                            } else if (pcReleaseStatus === "Release" || xboxReleaseStatus === "Release") {
                                return "Release";
                            } else {
                                return "Error";
                            }
                        },
                        maxWidth: 15,
                    },
                    {
                        id: "contentGameType",
                        Header: 'Type',
                        accessor: (row: MarketplaceProductBrowserData) => getLatestMarketplacePackageSummaryResponse(row.marketplacePackageSummary)?.packageName?.startsWith("fs20") ? "2020" : "2024",
                        maxWidth: 10
                    }
                ]
            },
            {
                id: "packageName",
                Header: 'Package Name',
                accessor: (row: MarketplaceProductBrowserData) => row.marketplacePackageSummary.packageName
            },
            {
                id: 'packageContentType',
                Header: 'Type',
                accessor: (row: MarketplaceProductBrowserData) => getLatestMarketplacePackageSummaryResponse(row.marketplacePackageSummary)?.packageContentType ?? "Error",
                maxWidth: 15
            },
            {
                Header: "Action",
                accessor: 'action',
                disableSortBy: true,
                disableColumnFilter: true,
                maxWidth: 20,
                Cell: ({row}: {row: {index: number, original: MarketplaceProductBrowserData}}) => {
                    const contentStatus = row.original.contentPackageDetails?.processingStatus;
                    const contentIsProcessing = contentStatus?.PC != null && contentStatus?.PC !== SubmissionType.None ||
                                         contentStatus?.Xbox != null && contentStatus?.Xbox !== SubmissionType.None ||
                                         contentStatus?.PCAndXbox != null && contentStatus?.PCAndXbox !== SubmissionType.None;
                    const marketplaceIsProcessing =
                        getLatestMarketplacePackageSummaryResponse(row.original.marketplacePackageSummary)?.ingestionStatus === MisStatus.Commited ||
                        row.original.marketplacePackageSummary?.processingStatus != null && row.original.marketplacePackageSummary?.processingStatus !== SubmissionType.None;

                    return <div >
                        <span className={"fa-stack fa-1x"} style={{marginRight: "-4px"}}>
                            <FontAwesomeIcon icon={faGift} style={{cursor: contentIsProcessing ? "not-allowed" : "pointer"}} className={"icon fa-stack-1x"} onClick={() => onEditContentPackage(row.index, row.original.marketplacePackageSummary)} />
                            <FontAwesomeIcon icon={faBan} style={{cursor: "not-allowed", color: "red", visibility: contentIsProcessing ? "unset" : "hidden"}} className="icon fa-stack-2x" />
                        </span>
                        <span className={"fa-stack fa-1x"} style={{marginLeft: "-4px", marginRight: "-4px"}}>
                            <FontAwesomeIcon icon={faEdit} style={{cursor: marketplaceIsProcessing ? "not-allowed" : "pointer"}} className={"icon fa-stack-1x"} onClick={() => onEdit(row.original.marketplacePackageSummary)} />
                            <FontAwesomeIcon icon={faBan} style={{cursor: "not-allowed", color: "red", visibility: marketplaceIsProcessing ? "unset" : "hidden"}} className="icon fa-stack-2x" />
                        </span>
                        <span className={"fa-stack fa-1x"} style={{marginLeft: "-4px", marginRight: "4px"}}>
                            <FontAwesomeIcon icon={faPaperPlane} style={{cursor: contentIsProcessing || marketplaceIsProcessing ? "not-allowed" : "pointer"}} className={"icon fa-stack-1x"} onClick={() => onRelease(row.index, row.original.marketplacePackageSummary)} />
                            <FontAwesomeIcon icon={faBan} style={{cursor: "not-allowed", color: "red", visibility: contentIsProcessing || marketplaceIsProcessing ? "unset" : "hidden"}} className="icon fa-stack-2x" />
                        </span>
                        {isAdministrator && <FontAwesomeIcon icon={faTrash} style={{cursor: "pointer", paddingBottom: "8px", paddingTop: "8px", verticalAlign: "middle"}} className="icon" onClick={() => onDelete(row.index, row.original.marketplacePackageSummary)} />}
                    </div>
                }
            }
        ]

        return columns;
    }

    static getColumnDetailsHeaders() {
        var columnStyle = {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center'
        }

        const columns = [
            {
                Header: 'Publisher Id',
                accessor: 'publisherId', // String-based value accessors!
                maxWidth: 80,
                style: columnStyle
            },
            {
                Header: 'Developer Id',
                accessor: 'developerId', // String-based value accessors!
                maxWidth: 200,
                style: columnStyle
            },
            {
                Header: 'Package Version',
                accessor: 'packageVersion', // String-based value accessors!
                maxWidth: 65,
                style: columnStyle
            }
        ]

        return columns;
    }

    convertToColumnData() {
        var reportEntry = this.export();

        return (
            {
                submission: this,
                packageVersion: reportEntry.packageVersion,
                publisherId: reportEntry.publisherId,
                releaseStatus: reportEntry.releaseStatus,
                developerId: reportEntry.developerId,
                packageName: reportEntry.packageName,
                packageContentType: reportEntry.packageContentType,
                priceInUSD: reportEntry.priceInUSD,
                title: reportEntry.title,
                ingestionStatus: reportEntry.ingestionStatus
            }
        )
    }

    static get defaultListSorting() {
        return [
            {
                id: "developerId",
                desc: false
            },
            {
                id: "title",
                desc: false
            }
        ]
    }

    static get defaultFirstPartyListSorting() {
        return [
            {
                id: "creatorName",
                desc: false
            },
            {
                id: "title",
                desc: false
            }
        ]
    }

    static get MAX_FILE_SIZE() {
        return MAX_UPLOAD_SIZE;
    }
}