import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import Auth from '../Auth/Auth'
import { MarketplacePackage } from '../Models/AugerOffers/MarketplacePackage';
import { MarketplaceSummary, MarketplaceSummaryData, MisPackageDetailsResponse, MisStatus, PackageDetails, PlatformDetails, PlatformProcessingStatus, SubmissionType } from '../Models/AugerOffers/MarketplaceDetails';
import { ContentPackage } from '../Models/AugerOffers/ContentPackage';
import ReactTable from '../ReactTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestion, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import loadingsmall from '../Images/loadingsmall.svg';
import { VersionCompare } from '../Constants/utils';
import { GetMarketplacePackageDetails } from '../Api/RomaWebApi';

interface Props {
    auth: Auth,
    isNew: boolean,
    displayOlderVersions: boolean,
    onSave: () => Promise<void>,
    onCancel: () => void,
    processFormInfo: (marketplacePackage: MarketplacePackage | undefined, propertyName: keyof MarketplacePackage, propertyValue?: any) => void,
    marketplacePackage: MarketplacePackage,
    marketplacePackageSummary: MarketplaceSummaryData,
    marketplacePackageSummaries: MarketplaceSummaryData[],
    contentPackage: ContentPackage,
    contentPackagesDetails: PackageDetails<PlatformDetails, PlatformProcessingStatus>[]
}

export function MarketplaceProductDetails(props: Props) {
    const [prereleaseMarketplaceDetails, setPrereleaseMarketplaceDetails] = useState<PackageDetails<MisPackageDetailsResponse, SubmissionType> | undefined>();
    const [releaseMarketplaceDetails, setReleaseMarketplaceDetails] = useState<PackageDetails<MisPackageDetailsResponse, SubmissionType> | undefined>();
    const [loadingPrereleaseMarketplaceDetails, setLoadingPrereleaseMarketplaceDetails] = useState<boolean>(true);
    const [loadingReleaseMarketplaceDetails, setLoadingReleaseMarketplaceDetails] = useState<boolean>(true);

    useEffect(() => {
        const fetchPrereleaseDetails = async () => {
            try {
                if (props.marketplacePackageSummary?.prerelease?.submissionId) {
                    const result = await GetMarketplacePackageDetails(props.auth, props.marketplacePackageSummary.packageName, props.marketplacePackageSummary.prerelease.submissionId);
                    setPrereleaseMarketplaceDetails(result);
                }
            } catch (error) {
                console.log("Error getting marketplace prerelease details: ", error);
            } finally {
                setLoadingPrereleaseMarketplaceDetails(false);
            }
        };

        fetchPrereleaseDetails();

        const fetchReleaseDetails = async () => {
            try {
                if (props.marketplacePackageSummary?.release?.submissionId) {
                    const result = await GetMarketplacePackageDetails(props.auth, props.marketplacePackageSummary.packageName, props.marketplacePackageSummary.release.submissionId);
                    setReleaseMarketplaceDetails(result);
                }
            } catch (error) {
                console.log("Error getting marketplace release details: ", error);
            } finally {
                setLoadingReleaseMarketplaceDetails(false);
            }
        };

        fetchReleaseDetails();
    }, [props.auth, props.marketplacePackageSummary]);

    var columnStyle = {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
    }

    const columns = [
        {
            id: "platform",
            accessor: (row: any) => row.platform,
            maxWidth: 20,
            style: columnStyle
        },
        {
            Header: 'Draft',
            columns: [
                {
                    id: "draftVersion",
                    Header: 'Version',
                    accessor: (row: any) => row.draft.version,
                    maxWidth: 10,
                    style: columnStyle,
                    Cell: ({row}: {row: {original: TableData, values: any}}) => {
                        if (row.values["draftStatus"] === "Pending") {
                            return <div style={{borderRadius: "10px", color: "lightgrey", background: "lightgrey"}}>Pending</div>;
                        }
    
                        return row.values["draftVersion"];
                    }
                },
                {
                    id: "draftSubmissionId",
                    Header: 'Ingested',
                    accessor: (row: any) => row.draft.submissionId,
                    maxWidth: 25,
                    style: columnStyle,
                    Cell: ({row}: {row: {original: TableData, values: any}}) => {
                        if (row.values["draftStatus"] === "Pending") {
                            return <div style={{borderRadius: "10px", color: "lightgrey", background: "lightgrey"}}>Pending</div>;
                        }
    
                        return row.values["draftSubmissionId"];
                    }
                },
                {
                    id: "draftStatus",
                    Header: 'Status',
                    accessor: (row: any) => row.draft.status,
                    maxWidth: 15,
                    style: columnStyle,
                    Cell: ({row}: {row: {original: TableData, values: any}}) => {
                        if (row.values["draftStatus"] === "Pending") {
                            return <img src={loadingsmall} style={{ maxWidth: 20, display: "block", margin: "auto" }} alt="loading" />;
                        } else if (row.values["draftStatus"] === "Error") {
                            return <FontAwesomeIcon icon={faTriangleExclamation} style={{color: "red"}}/>;
                        } else if (row.values["draftStatus"] === "Unknown") {
                            return <FontAwesomeIcon icon={faQuestion} style={{color: "orange"}}/>;
                        }
    
                        return row.values["draftStatus"];
                    }
                },
                {
                    id: "draftGameType",
                    Header: 'Type',
                    accessor: (row: any) => row.draft.type,
                    maxWidth: 15,
                    style: columnStyle,
                    Cell: ({row}: {row: {original: TableData, values: any}}) => {
                        if (row.values["draftStatus"] === "Pending") {
                            return <div style={{borderRadius: "10px", color: "lightgrey", background: "lightgrey"}}>Pending</div>;
                        }
    
                        return row.values["draftGameType"];
                    }
                }
            ]
        },
        {
            Header: 'Prerelease',
            columns: [
                {
                    id: "prereleaseVersion",
                    Header: 'Version',
                    accessor: (row: any) => row.prerelease.version,
                    maxWidth: 10,
                    style: columnStyle,
                    Cell: ({row}: {row: {original: TableData, values: any}}) => {
                        if (row.values["prereleaseStatus"] === "Pending") {
                            return <div style={{borderRadius: "10px", color: "lightgrey", background: "lightgrey"}}>Pending</div>;
                        }
    
                        return row.values["prereleaseVersion"];
                    }
                },
                {
                    id: "prereleaseSubmissionId",
                    Header: 'Ingested',
                    accessor: (row: any) => row.prerelease.submissionId,
                    maxWidth: 25,
                    style: columnStyle,
                    Cell: ({row}: {row: {original: TableData, values: any}}) => {
                        if (row.values["prereleaseStatus"] === "Pending") {
                            return <div style={{borderRadius: "10px", color: "lightgrey", background: "lightgrey"}}>Pending</div>;
                        }
    
                        return row.values["prereleaseSubmissionId"];
                    }
                },
                {
                    id: "prereleaseStatus",
                    Header: 'Status',
                    accessor: (row: any) => row.prerelease.status,
                    maxWidth: 15,
                    style: columnStyle,
                    Cell: ({row}: {row: {original: TableData, values: any}}) => {
                        if (row.values["prereleaseStatus"] === "Pending") {
                            return <img src={loadingsmall} style={{ maxWidth: 20, display: "block", margin: "auto" }} alt="loading" />;
                        } else if (row.values["prereleaseStatus"] === "Error") {
                            return <FontAwesomeIcon icon={faTriangleExclamation} style={{color: "red"}}/>;
                        } else if (row.values["prereleaseStatus"] === "Unknown") {
                            return <FontAwesomeIcon icon={faQuestion} style={{color: "orange"}}/>;
                        }
    
                        return row.values["prereleaseStatus"];
                    }
                },
                {
                    id: "prereleaseGameType",
                    Header: 'Type',
                    accessor: (row: any) => row.prerelease.type,
                    maxWidth: 15,
                    style: columnStyle,
                    Cell: ({row}: {row: {original: TableData, values: any}}) => {
                        if (row.values["prereleaseStatus"] === "Pending") {
                            return <div style={{borderRadius: "10px", color: "lightgrey", background: "lightgrey"}}>Pending</div>;
                        }
    
                        return row.values["prereleaseGameType"];
                    }
                }
            ]
        },
        {
            Header: 'Release',
            columns: [
                {
                    id: "releaseVersion",
                    Header: 'Version',
                    accessor: (row: any) => row.release.version,
                    maxWidth: 10,
                    style: columnStyle,
                    Cell: ({row}: {row: {original: TableData, values: any}}) => {
                        if (row.values["releaseStatus"] === "Pending") {
                            return <div style={{borderRadius: "10px", color: "lightgrey", background: "lightgrey"}}>Pending</div>;
                        }
    
                        return row.values["releaseVersion"];
                    }
                },
                {
                    id: "releaseSubmissionId",
                    Header: 'Ingested',
                    accessor: (row: any) => row.release.submissionId,
                    maxWidth: 25,
                    style: columnStyle,
                    Cell: ({row}: {row: {original: TableData, values: any}}) => {
                        if (row.values["releaseStatus"] === "Pending") {
                            return <div style={{borderRadius: "10px", color: "lightgrey", background: "lightgrey"}}>Pending</div>;
                        }
    
                        return row.values["releaseSubmissionId"];
                    }
                },
                {
                    id: "releaseStatus",
                    Header: 'Status',
                    accessor: (row: any) => row.release.status,
                    maxWidth: 15,
                    style: columnStyle,
                    Cell: ({row}: {row: {original: TableData, values: any}}) => {
                        if (row.values["releaseStatus"] === "Pending") {
                            return <img src={loadingsmall} style={{ maxWidth: 20, display: "block", margin: "auto" }} alt="loading" />;
                        } else if (row.values["releaseStatus"] === "Error") {
                            return <FontAwesomeIcon icon={faTriangleExclamation} style={{color: "red"}}/>;
                        } else if (row.values["releaseStatus"] === "Unknown") {
                            return <FontAwesomeIcon icon={faQuestion} style={{color: "orange"}}/>;
                        }
    
                        return row.values["releaseStatus"];
                    }
                },
                {
                    id: "releaseGameType",
                    Header: 'Type',
                    accessor: (row: any) => row.release.type,
                    maxWidth: 15,
                    style: columnStyle,
                    Cell: ({row}: {row: {original: TableData, values: any}}) => {
                        if (row.values["releaseStatus"] === "Pending") {
                            return <div style={{borderRadius: "10px", color: "lightgrey", background: "lightgrey"}}>Pending</div>;
                        }
    
                        return row.values["releaseGameType"];
                    }
                }
            ]
        }];

    function getMarketplaceStatus(processingStatus: SubmissionType, details?: MarketplaceSummary) {
        return details?.ingestionStatus ?
            details?.ingestionStatus === MisStatus.Error ? "Error" :
            details?.ingestionStatus === MisStatus.Live && props.marketplacePackageSummary.processingStatus !== processingStatus ?
                "Live" :
                "Pending"
            : "";
    }

    function getPCStatus(processingStatus: SubmissionType, details?: PlatformDetails) {
        return details?.PC?.version ?
            (details?.PC?.isIngestionError ?
                "Error" :
                props.contentPackage.processingStatus.value?.PC === processingStatus || props.contentPackage.processingStatus.value?.PCAndXbox === processingStatus ?
                    "Pending" :
                    details?.PC?.isIngested || details?.PC?.isReleased ?
                        "Live" :
                        "Unknown") :
            "";
    }

    function getXboxStatus(processingStatus: SubmissionType, details?: PlatformDetails) {
        return details?.Xbox?.version ?
            (details?.Xbox?.isIngestionError ?
                "Error" :
                props.contentPackage.processingStatus.value?.Xbox === processingStatus || props.contentPackage.processingStatus.value?.PCAndXbox === processingStatus ?
                    "Pending" :
                    details?.Xbox?.isIngested || details?.PC?.isReleased ?
                        "Live" :
                        "Unknown") :
            "";
    }

    function parseSubmissionId(submissionId?: string) {
        const timestamp = submissionId?.split("Z")[0];

        if (timestamp?.length === submissionId?.length) {
            return submissionId;
        } else {
            return timestamp ? new Date(timestamp.replaceAll("_", ":") + "Z").toLocaleString() : "";
        }
    }

    const draftMarkplaceVersion = props.marketplacePackageSummary.draft?.ingestionVersion ?? -1;
    const prereleaseMarketplaceVersion = prereleaseMarketplaceDetails?.draft?.ingestionVersion ?? prereleaseMarketplaceDetails?.prerelease?.ingestionVersion ?? -1;
    const releaseMarketplaceVersion = releaseMarketplaceDetails?.draft?.ingestionVersion ?? releaseMarketplaceDetails?.prerelease?.ingestionVersion ?? releaseMarketplaceDetails?.release?.ingestionVersion ?? -1;

    const displayDraftMarketplace = props.marketplacePackageSummary.draft && (props.displayOlderVersions ||
        props.marketplacePackageSummary.processingStatus === SubmissionType.Draft ||
        draftMarkplaceVersion > prereleaseMarketplaceVersion && draftMarkplaceVersion > releaseMarketplaceVersion);
    const displayDraftPCContent = props.displayOlderVersions ||
        props.contentPackage.processingStatus.value?.PC === SubmissionType.Draft ||
        props.contentPackage.processingStatus.value?.PCAndXbox === SubmissionType.Draft ||
        VersionCompare(props.contentPackage.draft.value?.PC?.version, props.contentPackage.prerelease.value?.PC?.version) < 0 &&
        VersionCompare(props.contentPackage.draft.value?.PC?.version, props.contentPackage.release.value?.PC?.version) < 0;
    const displayDraftXboxContent = props.displayOlderVersions ||
        props.contentPackage.processingStatus.value?.Xbox === SubmissionType.Draft ||
        props.contentPackage.processingStatus.value?.PCAndXbox === SubmissionType.Draft ||
        VersionCompare(props.contentPackage.draft.value?.Xbox?.version, props.contentPackage.prerelease.value?.Xbox?.version) < 0 &&
        VersionCompare(props.contentPackage.draft.value?.Xbox?.version, props.contentPackage.release.value?.Xbox?.version) < 0;
    const displayPrereleaseMarketplace = props.marketplacePackageSummary.prerelease && (props.displayOlderVersions ||
        props.marketplacePackageSummary.processingStatus === SubmissionType.Prerelease ||
        prereleaseMarketplaceVersion > releaseMarketplaceVersion);
    const displayPrereleasePCContent = props.displayOlderVersions ||
        props.contentPackage.processingStatus.value?.PC === SubmissionType.Prerelease ||
        props.contentPackage.processingStatus.value?.PCAndXbox === SubmissionType.Prerelease ||
        VersionCompare(props.contentPackage.prerelease.value?.PC?.version, props.contentPackage.release.value?.PC?.version) < 0;
    const displayPrereleaseXboxContent = props.displayOlderVersions ||
        props.contentPackage.processingStatus.value?.Xbox === SubmissionType.Prerelease ||
        props.contentPackage.processingStatus.value?.PCAndXbox === SubmissionType.Prerelease ||
        VersionCompare(props.contentPackage.prerelease.value?.Xbox?.version, props.contentPackage.release.value?.Xbox?.version) < 0;

    interface PlatformData {
        version: string;
        submissionId: string;
        status: string;
        type: string;
    }

    interface TableData {
        platform: string;
        draft: PlatformData;
        prerelease: PlatformData;
        release: PlatformData;
    }

    const tableData = [
        {
            platform: "Marketplace",
            draft: displayDraftMarketplace ? {
                version: props.marketplacePackageSummary.draft.ingestionVersion ?? "",
                submissionId: parseSubmissionId(props.marketplacePackageSummary.draft?.submissionId),
                status: getMarketplaceStatus(SubmissionType.Draft, props.marketplacePackageSummary.draft) ?? "",
                type: props.marketplacePackageSummary.draft?.packageName?.startsWith("fs20") ? "2020" : props.marketplacePackageSummary.draft?.packageName?.startsWith("fs24") ? "2024" : ""
            } : {},
            prerelease: displayPrereleaseMarketplace ? {
                version: props.marketplacePackageSummary.prerelease.ingestionVersion + (prereleaseMarketplaceDetails?.draft?.ingestionVersion != null ? " (" + prereleaseMarketplaceDetails?.draft?.ingestionVersion + ")" : ""),
                submissionId: parseSubmissionId(props.marketplacePackageSummary.prerelease?.submissionId),
                status: getMarketplaceStatus(SubmissionType.Prerelease, props.marketplacePackageSummary.prerelease) ?? "",
                type: props.marketplacePackageSummary.prerelease?.packageName?.startsWith("fs20") ? "2020" : props.marketplacePackageSummary.prerelease?.packageName?.startsWith("fs24") ? "2024" : ""
            } : {},
            release: props.marketplacePackageSummary.release ? {
                version: props.marketplacePackageSummary.release.ingestionVersion + (releaseMarketplaceDetails?.draft?.ingestionVersion != null ? " (" + releaseMarketplaceDetails?.draft?.ingestionVersion + ")" : ""),
                submissionId: parseSubmissionId(props.marketplacePackageSummary.release?.submissionId),
                status: getMarketplaceStatus(SubmissionType.Release, props.marketplacePackageSummary.release) ?? "",
                type: props.marketplacePackageSummary.release?.packageName?.startsWith("fs20") ? "2020" : props.marketplacePackageSummary.release?.packageName?.startsWith("fs24") ? "2024" : ""
            } : {}
        },
        {
            platform: "PC",
            draft: displayDraftPCContent ? {
                version: props.contentPackage.draft.value?.PC?.version ?? "",
                submissionId: parseSubmissionId(props.contentPackage.draft.value?.PC?.submissionId),
                status: getPCStatus(SubmissionType.Draft, props.contentPackage.draft.value),
                type: props.contentPackage.draft?.value?.PC?.packageName?.startsWith("fs20") ? "2020" : props.contentPackage.draft?.value?.PC?.packageName?.startsWith("fs24") ? "2024" : ""
            } : {},
            prerelease: displayPrereleasePCContent ? {
                version: props.contentPackage.prerelease.value?.PC?.version ?? "",
                submissionId: parseSubmissionId(props.contentPackage.prerelease.value?.PC?.submissionId),
                status: getPCStatus(SubmissionType.Prerelease, props.contentPackage.prerelease.value),
                type: props.contentPackage.prerelease?.value?.PC?.packageName?.startsWith("fs20") ? "2020" : props.contentPackage.prerelease?.value?.PC?.packageName?.startsWith("fs24") ? "2024" : ""
            } : {},
            release: {
                version: props.contentPackage.release.value?.PC?.version ?? "",
                submissionId: parseSubmissionId(props.contentPackage.release.value?.PC?.submissionId),
                status: getPCStatus(SubmissionType.Release, props.contentPackage.release.value),
                type: props.contentPackage.release?.value?.PC?.packageName?.startsWith("fs20") ? "2020" : props.contentPackage.release?.value?.PC?.packageName?.startsWith("fs24") ? "2024" : ""
            }
        },
        {
            platform: "Xbox",
            draft: displayDraftXboxContent ? {
                version: props.contentPackage.draft.value?.Xbox?.version ?? "",
                submissionId: parseSubmissionId(props.contentPackage.draft.value?.Xbox?.submissionId),
                status: getXboxStatus(SubmissionType.Draft, props.contentPackage.draft.value),
                type: props.contentPackage.draft?.value?.Xbox?.packageName?.startsWith("fs20") ? "2020" : props.contentPackage.draft?.value?.Xbox?.packageName?.startsWith("fs24") ? "2024" : ""
            } : {},
            prerelease: displayPrereleaseXboxContent ? {
                version: props.contentPackage.prerelease.value?.Xbox?.version ?? "",
                submissionId: parseSubmissionId(props.contentPackage.prerelease.value?.Xbox?.submissionId),
                status: getXboxStatus(SubmissionType.Prerelease, props.contentPackage.prerelease.value),
                type: props.contentPackage.prerelease?.value?.Xbox?.packageName?.startsWith("fs20") ? "2020" : props.contentPackage.prerelease?.value?.Xbox?.packageName?.startsWith("fs24") ? "2024" : ""
            } : {},
            release: {
                version: props.contentPackage.release.value?.Xbox?.version ?? "",
                submissionId: parseSubmissionId(props.contentPackage.release.value?.Xbox?.submissionId),
                status: getXboxStatus(SubmissionType.Release, props.contentPackage.release.value),
                type: props.contentPackage.release?.value?.Xbox?.packageName?.startsWith("fs20") ? "2020" : props.contentPackage.release?.value?.Xbox?.packageName?.startsWith("fs24") ? "2024" : ""
            }
        }
    ] as TableData[];

    return (
        <main className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <Card style={{margin: 20}}>
                    <div>
                        <ReactTable
                            style={{clear:'right'}}
                            data={tableData}
                            columns={columns}
                            loading={!props.contentPackage && !props.marketplacePackageSummary || loadingPrereleaseMarketplaceDetails || loadingReleaseMarketplaceDetails}
                            minRows={1}
                            noDataText = {"No data!"}
                            defaultSorted={ [] }
                            defaultPageSize={10}
                            disableSortBy={true}
                            className="-striped -highlight"
                            hideSearch={true}
                            hidePagination={true} />
                    </div>
                </Card>
            </main>
    );
}
